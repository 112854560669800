import React, { useState, useEffect } from 'react';
import './App.css';
import './index.css';
import Main from './Containers/Main.js'
import Footer from './Components/Footer.js'
import { withRouter } from "react-router-dom";
import { useStaticQuery, graphql } from 'gatsby';

const  App = ({ data, pageContext }) => {
  let currentIssue = pageContext.currentIssue ?  pageContext.currentIssue.node : {highlightColor: '', lowlightColor: '', slug: '', topic: ''}
  let currentArticles = data.postgres.articles.edges || [{},{},{},{}]
  
  // only for build
  if(!!currentArticles[0].node){
    currentArticles.forEach((a,i) => {
      currentArticles[i] = a.node
    })
  }

  if (typeof(document) !== 'undefined'){
    document.documentElement.style.background = `#${currentIssue.lowlightColor}`
    document.documentElement.style.color = `#${currentIssue.highlightColor}`
  }

  const [ topic, setTopic ] = useState('This Month:')
  const [ articles, setArticles ] = useState([...currentArticles])

    return (
      <>
        <Main
          topic = {currentIssue.topic}
          articles = {articles}
          highlightColor={currentIssue.highlightColor}
          lowlightColor={currentIssue.lowlightColor}
          />
          <Footer></Footer>
      </>
    );
}

export default App;
export const query = graphql`
query CurrentArticlesQuery ($currentIssueId : Int) {
  postgres {
    articles(condition: {issue: $currentIssueId}) {
      edges {
        node {
          id
          title
          slug
          pubDate
          heroImageUrl
          authorName
        }
      }
    }
  }
}
`
export function Head (){
  return (
    <>
       <meta charSet="utf-8" />
       {/* <link rel="icon" href="%PUBLIC_URL%/favicon.ico" /> */}
       <meta name="viewport" content="width=device-width, initial-scale=1" />
       <meta name="theme-color" content="#000000" />
       <meta name="description"
         content="Monthly magazine for long-form articles and podcasts on videogames"
       />
        <link rel="preconnect" href="https://fonts.gstatic.com" /> 
       <link href="https://fonts.googleapis.com/css2?family=Bitter&family=JetBrains+Mono:wght@400&display=swap" rel="stylesheet" />
       
       <title>Bullet Points Monthly</title>
    </>
  )
}